/**
 * @module productBlocks
 */
module.exports = function() {
    const showRow = document.querySelector( '.show-row' );
    const sortUp = document.querySelector( '.sort-up' );
    const sortDown = document.querySelector( '.sort-down' );

    const toggleDetails = ( detailsBlock, isShowRow ) => {
        if ( isShowRow ) {
            detailsBlock.textContent = detailsBlock.getAttribute( 'data-hide-block' );
            sortDown.classList.add( 'd-none' );
            sortUp.classList.remove( 'd-none' );
        } else {
            detailsBlock.textContent = detailsBlock.getAttribute( 'data-show-block' );
            sortDown.classList.remove( 'd-none' );
            sortUp.classList.add( 'd-none' );
        }
    };

    if ( getComputedStyle( showRow ).visibility !== 'hidden' ) {
        const showDetailsBtn = showRow.parentElement.querySelector( '.details-block p' );
        toggleDetails( showDetailsBtn, true );
    }

    document.querySelectorAll( '.product-item .details-block' ).forEach( ( item ) => {
        item.addEventListener( 'click', function() {
            const detailsBlock = this.querySelector( 'p' );
            const toggleRow = this.closest( '.product-item' ).querySelector( '.toggle-row' );
            const isShowRow = toggleRow.classList.toggle( 'show-row' );

            toggleRow.classList.toggle( 'hide-row', ! isShowRow );
            toggleDetails( detailsBlock, isShowRow );
        } );
    } );
};
